"use client"

import { usePathname } from "next/navigation"
import React, { useCallback, useEffect, useRef } from "react"
import useSWRMutation from "swr/mutation"
import { useAuthentication } from "../../hooks/authentication"
import { useRuntimeConfig } from "../../hooks/runtimeConfig"
import { useUser } from "../../hooks/user"
import useScript from "../../hooks/useScript"
import { BrandsEnum } from "../../types"
import {
  fetchWithConfig,
  IFetchWithConfigOptions,
  SWR_KEYS,
} from "../../utils/rest"
import { useMediaQuery, useTheme } from "../_common"

const FRESH_CHAT_LOCALE = "de"

function isPageInScope(restrictedPages = "", pathname: string) {
  const paths = restrictedPages.split(",")

  for (let i = 0; i < paths.length; i++) {
    if (paths[i].split("/")[1] === pathname.split("/")[1]) {
      return true
    }
  }

  return false
}

function toggleFreshChatVisibility(shouldHideWidget: boolean) {
  window.fcWidget?.setConfig({
    headerProperty: { hideChatButton: shouldHideWidget },
  })
}

function getPropsForBrand(mandatorNo: BrandsEnum) {
  if (mandatorNo === BrandsEnum.VIVERSUM_GERMANY) {
    return { appName: "Viversum", product: "Viversum-NFE" }
  }

  return { appName: "Questico", product: "Questico-NFE" }
}

async function customerChatLogout() {
  if (!window.fcWidget) return

  try {
    const { data: userExist } = await window.fcWidget?.user.isExists()

    if (userExist === true) {
      await window.fcWidget?.user.clear()
    }
  } catch (error) {
    console.error(`customerChatLogout error`, error)
  } finally {
    window.fcWidget?.destroy()
  }
}

interface ICustomerChatProps {
  isAuthenticated: boolean
  shouldHideWidget: boolean
}

/**
 * Link to documentation: https://developers.freshchat.com/web-sdk/#messenger-api
 */
const CustomerChat = ({
  shouldHideWidget,
  isAuthenticated,
}: ICustomerChatProps) => {
  const { getRuntimeConfig } = useRuntimeConfig()
  const initialisationCalled = useRef(false)

  const theme = useTheme()

  const {
    state: {
      user: { details },
      initialLoadReady,
    },
  } = useUser()

  const { trigger: updateRestoreId } = useSWRMutation(
    SWR_KEYS.FRESHCHAT_RESTORE_ID,
    (url, { arg: { id }, headers }: IFetchWithConfigOptions<{ id: string }>) =>
      fetchWithConfig<{
        reference: string
      }>(
        {
          url,
          data: {
            restore_id: id,
          },
          headers,
          method: "PATCH",
        },
        getRuntimeConfig
      )
  )

  const customerChatInit = useCallback(() => {
    const {
      id,
      freshchat_restore_id: freshchatRestoreId,
      email,
      personal,
    } = details

    const { first_name: firstName, last_name: lastName, phone } = personal || {}

    const {
      FRESH_CHAT_TOKEN,
      FRESHCHAT_WIDGET_URL,
      MANDATOR_NUMBER,
    } = getRuntimeConfig()

    const { appName, product } = getPropsForBrand(MANDATOR_NUMBER)

    window.fcWidget?.init({
      token: FRESH_CHAT_TOKEN,
      host: FRESHCHAT_WIDGET_URL,
      locale: FRESH_CHAT_LOCALE,
      siteId: product,
      config: {
        headerProperty: {
          appName,
          backgroundColor: theme.palette.contextualColors.brandPrimary,
        },
        agent: {
          hideName: true,
          hidePic: true,
          hideBio: true,
        },
        cssNames: {
          widget: "custom_fc_frame",
        },
      },

      externalId: id,
      restoreId: freshchatRestoreId || null,
    })

    window.fcWidget?.user.get((resp: { status: number }) => {
      window.fcWidget?.user.setProperties({
        email,
        product,
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
        ...(phone && { phone: `+${phone}` }),
      })

      if (resp.status !== 200) {
        window.fcWidget?.on("user:created", async (response) => {
          if (response && response.status === 200 && response.data?.restoreId) {
            await updateRestoreId({ id: response.data.restoreId })
          }
        })
      }
    })
  }, [
    details,
    getRuntimeConfig,
    theme.palette.contextualColors.brandPrimary,
    updateRestoreId,
  ])

  const onWidgetLoaded = useCallback(() => {
    toggleFreshChatVisibility(shouldHideWidget)
  }, [shouldHideWidget])

  useEffect(() => {
    if (!isAuthenticated && window.fcWidget?.isInitialized()) {
      initialisationCalled.current = false
      customerChatLogout()
    } else if (
      !initialisationCalled.current &&
      initialLoadReady &&
      !window.fcWidget?.isInitialized()
    ) {
      initialisationCalled.current = true
      customerChatInit()
    }
  }, [customerChatInit, initialLoadReady, isAuthenticated])

  /**
   * `"widget:loaded"` isn't called "immediately" and not related to
   * initialisation or to `isScriptLoaded`. Sequence:
   * First: user interacts somehow with a page (scroll, mouse move, etc)
   * Second: freshchat fires necessary network requests
   * Third: event is fired
   */
  useEffect(() => {
    window.fcWidget?.on("widget:loaded", onWidgetLoaded)

    return () => {
      window.fcWidget?.off("widget:loaded", onWidgetLoaded)
    }
  }, [])

  useEffect(() => {
    toggleFreshChatVisibility(shouldHideWidget)
  }, [shouldHideWidget])

  return null
}

const FreshChat: React.FC = () => {
  const { getRuntimeConfig, getRuntimeConfigByKey } = useRuntimeConfig()
  const { isAuthenticated } = useAuthentication()

  const pathname = usePathname()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const [isScriptLoaded] = useScript({
    src: `${getRuntimeConfigByKey("FRESHCHAT_WIDGET_URL")}/js/widget.js`,
  })

  const {
    FRESH_CHAT_IS_ENABLED,
    FRESH_CHAT_RESTRICTED_PAGES,
  } = getRuntimeConfig()

  if (!FRESH_CHAT_IS_ENABLED || !isScriptLoaded) return null

  const shouldHideWidget =
    isPageInScope(FRESH_CHAT_RESTRICTED_PAGES, pathname) && isMobile

  return (
    <CustomerChat
      isAuthenticated={isAuthenticated}
      shouldHideWidget={shouldHideWidget}
    />
  )
}

export default FreshChat
