import React, { useState, useCallback, useEffect, ReactNode } from "react"

import useSWR, { BareFetcher } from "swr"
import useSWRMutation from "swr/mutation"
import { useAuthentication } from "../authentication"
import sentryUtils from "../../utils/sentryUtils"
import MyFavoritesContext from "./MyFavoritesContext"
import { IRecentAction } from "./types"
import { fetchWithConfig, IFetchWithConfigOptions } from "../../utils/rest"
import { useRuntimeConfig } from "../runtimeConfig"
import { IAdvisorListingFetchResult } from "../../ui-lib/Experts/ExpertsService/hooks/types"
import { ClientTrackingEventName } from "../../types"
import { useTracking } from "../../hooks/tracking"

const MY_FAVORITES_IDS_COUNT = 10000
const MyFavoritesProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useAuthentication()
  const { trackEvent } = useTracking()
  const { getRuntimeConfig, getRuntimeConfigByKey } = useRuntimeConfig()

  const [recentAction, setRecentAction] = useState<IRecentAction | null>(null)

  const {
    isLoading: favoritesLoading,
    data,
    mutate,
    error: favoritesError,
  } = useSWR<IAdvisorListingFetchResult>(
    isAuthenticated ? "allFavorites" : null,
    ((_, { headers }) => {
      return fetchWithConfig(
        {
          url: "advisor/list",
          data: { count: MY_FAVORITES_IDS_COUNT, favorite: "on" },
          method: "POST",
          headers,
        },
        getRuntimeConfig,
        "advice"
      )
    }) as BareFetcher<IAdvisorListingFetchResult>
  )

  const experts = data?.list || []

  const expertsIds = experts.map(({ id }) => id)

  const { trigger: updateFavoriteStatus } = useSWRMutation(
    "advisor/favorite",
    async (_, { arg, headers }: IFetchWithConfigOptions) => {
      return fetchWithConfig({ ...arg, headers }, getRuntimeConfig, "advice")
    }
  )

  const updateFavorites = useCallback(
    async (
      id: number,
      add: boolean,
      listingNumber: string,
      enabledUndo = false
    ) => {
      try {
        await updateFavoriteStatus({
          url: "advisor/favorite",
          method: add ? "POST" : "DELETE",
          // eslint-disable-next-line camelcase
          data: { advisor_id: id },
        })

        await mutate()

        const recentAction: IRecentAction = {
          action: add ? "add" : "remove",
          enabledUndo,
          id,
          listingNumber,
        }

        setRecentAction(recentAction)
        trackEvent({
          eventName: add
            ? ClientTrackingEventName.PRODUCT_FAVOURITE_ADD
            : ClientTrackingEventName.PRODUCT_FAVOURITE_REMOVE,
          properties: {
            // eslint-disable-next-line camelcase
            product_id: `${getRuntimeConfigByKey(
              "MANDATOR_NUMBER"
            )}-${listingNumber}`,
            // eslint-disable-next-line camelcase
            expert_listing_no: listingNumber,
          },
        })
      } catch (error) {
        sentryUtils.report({
          message: `Failed to ${add ? "add" : "remove"} favorite expert`,
          error,
        })
      }
    },
    [getRuntimeConfigByKey, mutate, trackEvent, updateFavoriteStatus]
  )

  const checkIsFavourite = useCallback(
    (id: number) => Boolean(id && expertsIds.includes(id)),
    [expertsIds]
  )

  useEffect(() => {
    const timerId = window.setTimeout(() => {
      if (recentAction !== null) setRecentAction(null)
    }, 5000)

    return () => {
      window.clearTimeout(timerId)
    }
  }, [recentAction])

  const resetRecentAction = useCallback(() => {
    setRecentAction(null)
  }, [])

  return (
    <MyFavoritesContext.Provider
      value={{
        experts,
        expertsIds,
        updateFavorites,
        checkIsFavourite,
        recentAction,
        resetRecentAction,
        favoritesError,
        favoritesLoading,
      }}
    >
      {children}
    </MyFavoritesContext.Provider>
  )
}
export default MyFavoritesProvider
