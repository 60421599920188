"use client"

import track from "react-tracking"
import { createSharedTrackingFields, customDispatch } from "./utils"
import TrackingProvider from "./TrackingProvider"

export default track(
  {
    context: createSharedTrackingFields(),
  },
  {
    dispatch: customDispatch,
  }
)(TrackingProvider)
