import { Middleware, SWRHook } from "swr"
import { AxiosError } from "axios"
import { isAuthError } from "./utils"

const authErrorMiddleware: Middleware = (useSWRNext: SWRHook) => {
  return (key, fetcher, config) => {
    const swr = useSWRNext(key, fetcher, config)
    const { error } = swr
    const defaultSwrData = { ...swr }

    if (!isAuthError((error as unknown) as AxiosError)) {
      return defaultSwrData
    }

    return {
      ...defaultSwrData,
      error: undefined,
    }
  }
}

export default authErrorMiddleware
