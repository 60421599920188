import { AdviiCustomEvent } from "../../types"
import { isServer } from "../utils"

type PendingRequest = () => void | Promise<boolean>

class PendingRequests {
  private requests: PendingRequest[]

  constructor() {
    this.requests = []
    this.init()
  }

  init(): void {
    if (isServer()) {
      return
    }

    window.addEventListener(
      AdviiCustomEvent.TOKEN_REFRESH_SUCCESS,
      this.onTokenRefreshSuccess.bind(this)
    )
    window.addEventListener(
      AdviiCustomEvent.TOKEN_REFRESH_FAILURE,
      this.onTokenRefreshFailure.bind(this)
    )
  }

  addRequest(request: PendingRequest): void {
    this.requests.push(request)
  }

  resolvePendingRequests(): void {
    this.requests.map((callback) => callback())
    this.requests = []
  }

  onTokenRefreshSuccess(): void {
    this.resolvePendingRequests()
  }

  onTokenRefreshFailure(): void {
    this.requests = []
  }
}

export default new PendingRequests()
