"use client"
import * as React from "react"
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import NextAppDirEmotionCacheProvider from "./EmotionCache"
import { questicoTheme } from "../../themes/questico"
import { viversumTheme } from "../../themes/viversum"

export default function ThemeRegistry({
  children,
  isQuestico,
}: {
  children: React.ReactNode
  isQuestico: boolean
}) {
  const theme = isQuestico ? questicoTheme : viversumTheme
  return (
    <NextAppDirEmotionCacheProvider options={{ key: "mui", prepend: true }}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {children}
      </ThemeProvider>
    </NextAppDirEmotionCacheProvider>
  )
}
