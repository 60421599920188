"use client"

import React, { ComponentProps } from "react"
import { AbstractIntlMessages, NextIntlProvider } from "next-intl"
import { FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react"
import { FlagProvider } from "@unleash/nextjs/client"
import { ApolloProvider } from "@apollo/client"

import {
  RuntimeConfigProvider,
  useRuntimeConfig,
} from "../../hooks/runtimeConfig"
import { ConsentProvider } from "../../hooks/consent"
import ConditionalWrap from "../../ui-lib/ConditionalWrap"
import TrackingProvider from "../../hooks/tracking/TrackingProvider.client"
import { ViewportProvider } from "../../hooks/viewport"
import { DomRefsProvider } from "../../hooks/domRefs"
import { UserProvider } from "../../hooks/user"
import { AuthenticationProvider } from "../../hooks/authentication"
import { IJWTProfile, protectedRoutes } from "../../types"
import MyFavoritesProvider from "../../hooks/myFavorites/MyFavoritesProvider"
import { ProductServiceProvider } from "../../hooks/productService"
import FreshChat from "../../ui-lib/FreshChat"
import SWRWrapper from "../../hooks/api/SWRWrapper"
import ThemeRegistry from "../ThemeRegistry/ThemeRegistry"
import { makeClient } from "../../utils/graphql"
import PromotionsSnackbar from "../../ui-lib/Promotions/PromotionsSnackbar"
import { RateReminder, RateFlowModal } from "../../ui-lib/Rating"
import { BrowserFeaturesProvider } from "../../hooks/browserFeatures"

const Providers: React.FC<{
  messages: AbstractIntlMessages
  token: string | undefined
  jwtProfile: IJWTProfile | null | undefined
  isQuestico: boolean
  anonymousId: ComponentProps<typeof TrackingProvider>["anonymousId"]
  browserFeatures: ComponentProps<typeof BrowserFeaturesProvider>["features"]
  children: React.ReactNode
}> = ({
  children,
  messages,
  token,
  isQuestico,
  jwtProfile,
  anonymousId,
  browserFeatures,
}) => {
  const { getRuntimeConfig } = useRuntimeConfig()

  const {
    FINGERPRINTS_IS_ENABLED,
    FINGERPRINTJS_API_KEY,
    FINGERPTINTS_PROXY,
  } = getRuntimeConfig()

  const unleashConfig: ComponentProps<typeof FlagProvider>["config"] = {
    appName: "customer-fe",
    disableRefresh: true,
    metricsIntervalInitial: 0, // disable initial metric report
    metricsInterval: 300,
    context: {
      userId: jwtProfile?.user_id ? jwtProfile.user_id.toString() : undefined,
      properties: { anonymous_id: anonymousId.value },
    },
  }

  return (
    <FlagProvider config={unleashConfig}>
      <RuntimeConfigProvider>
        <BrowserFeaturesProvider features={browserFeatures}>
          <ConsentProvider>
            <NextIntlProvider messages={messages} locale="de">
              <ConditionalWrap
                condition={FINGERPRINTS_IS_ENABLED}
                wrap={(children) => (
                  <FpjsProvider
                    loadOptions={{
                      apiKey: FINGERPRINTJS_API_KEY,
                      region: "eu",
                      endpoint: FINGERPTINTS_PROXY,
                      scriptUrlPattern: `${FINGERPTINTS_PROXY}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
                    }}
                  >
                    {children}
                  </FpjsProvider>
                )}
              >
                <ApolloProvider client={makeClient()}>
                  <SWRWrapper>
                    <TrackingProvider anonymousId={anonymousId}>
                      <ViewportProvider>
                        <ThemeRegistry isQuestico={isQuestico}>
                          <DomRefsProvider>
                            <UserProvider user={undefined}>
                              <AuthenticationProvider
                                protectedPaths={protectedRoutes}
                                token={token}
                              >
                                <MyFavoritesProvider>
                                  <ProductServiceProvider>
                                    {children}

                                    <FreshChat />

                                    <PromotionsSnackbar />

                                    <RateReminder />

                                    <RateFlowModal />
                                  </ProductServiceProvider>
                                </MyFavoritesProvider>
                              </AuthenticationProvider>
                            </UserProvider>
                          </DomRefsProvider>
                        </ThemeRegistry>
                      </ViewportProvider>
                    </TrackingProvider>
                  </SWRWrapper>
                </ApolloProvider>
              </ConditionalWrap>
            </NextIntlProvider>
          </ConsentProvider>
        </BrowserFeaturesProvider>
      </RuntimeConfigProvider>
    </FlagProvider>
  )
}

export default Providers
