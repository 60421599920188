import { pxToRem, fontSize, htmlFontSize } from "./utils"
import { createTheme } from "@mui/material/styles"
import { Figtree } from "next/font/google"

const figtree = Figtree({ subsets: ["latin"] })

const contextualColors = {
  negative: "#FFFFFF",
  bgMain: "#F6F6F6",
  neutral: "#F1F0F0",
  uiSecondary: "#A8AAAF",
  textBody: "#6C6E77",
  uiPrimary: "#38424F",
  buttonDisabled: "#D8D8D8",
  bgSecondaryAccent: "#EEFAF6",
  bgPrimaryAccent: "#DFECEA",
  bgObstacle: "#FBF5E4",
  bgError: "#FFF0F3",
  bgVoucher: "#FFF1F6",
  bgFooter: "#2A2A2B", // only one usage, confirmed by designer
  bgStatusAvailable: "#F4FAF8",

  brandPrimary: "#0F5F5B",
  brandPrimaryHover: "#0B524E",
  brandPrimaryInactive: "#9ABCBA",
  brandSecondary: "#FF7E9D",
  brandSecondaryHover: "#DB5B88",
  iconsTextError: "#D0021B",
  iconsTextInfo: "#0077CC",
  iconsSuccess: "#02D57C",
  attention: "#F8B300",
  labelPremium: "linear-gradient(94.82deg, #FF943F 0.87%, #FFD269 100%)",
  labelAstroTV: "#8A83FF",
  labelNew: "#00C797",
}

const defaultTheme = createTheme()

export const viversumTheme = createTheme({
  breakpoints: defaultTheme.breakpoints,
  typography: {
    fontFamily: figtree.style.fontFamily,
    // @ts-ignore
    pxToRem,
    fontSize,
    htmlFontSize,
    h1: {
      fontSize: "2.613rem",
      fontWeight: "bold",
      lineHeight: 1.42,
      marginBottom: "1em",
      marginTop: 0,
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "2.986rem",
      },
    },
    h2: {
      fontSize: "2.177rem",
      fontWeight: "bold",
      lineHeight: 1.42,
      marginBottom: "1em",
      marginTop: 0,
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "2.488rem",
      },
    },
    h3: {
      fontSize: "1.815rem",
      fontWeight: "bold",
      lineHeight: 1.42,
      marginBottom: "1em",
      marginTop: 0,
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "2.074rem",
      },
    },
    h4: {
      fontSize: "1.512rem",
      fontWeight: "bold",
      lineHeight: 1.42,
      marginBottom: "1em",
      marginTop: 0,
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "1.728rem",
      },
    },
    h5: {
      fontSize: "1.26rem",
      fontWeight: "bold",
      lineHeight: 1.42,
      marginBottom: "1em",
      marginTop: 0,
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "1.44rem",
      },
    },
    h6: {
      fontSize: "1.05rem",
      fontWeight: "bold",
      lineHeight: 1.42,
      marginBottom: "1em",
      marginTop: 0,
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "1.2rem",
      },
    },
    body1: {
      fontSize: pxToRem(14),
    },
    // body2 is used in rich text paragraph
    body2: {
      fontSize: "0.875rem",
      marginBottom: "1.25em",
      marginTop: 0,
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "1rem",
      },
    },
    subtitle2: {
      fontSize: "0.875rem",
      lineHeight: 1.42,
      [defaultTheme.breakpoints.up("sm")]: {
        fontSize: "1rem",
      },
    },
  },
  direction: "ltr",
  palette: {
    mode: "light",
    common: { black: "#000", white: "#fff" },
    primary: {
      main: contextualColors.brandPrimary,
      dark: contextualColors.brandPrimaryHover,
      contrastText: contextualColors.negative,
    },
    secondary: {
      main: contextualColors.brandSecondary,
      dark: contextualColors.brandSecondaryHover,
      light: contextualColors.bgVoucher,
    },
    text: {
      primary: contextualColors.uiPrimary,
      secondary: contextualColors.textBody,
    },
    error: {
      main: contextualColors.iconsTextError,
    },
    success: {
      main: contextualColors.iconsSuccess,
    },
    action: {
      selected: contextualColors.bgSecondaryAccent,
    },
    background: {
      default: contextualColors.bgMain,
    },
    grey: {
      "100": contextualColors.bgMain,
      "300": contextualColors.buttonDisabled,
      "700": contextualColors.uiPrimary,
      A200: contextualColors.neutral,
    },
    contextualColors,
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${contextualColors.neutral}`,

          [defaultTheme.breakpoints.up("sm")]: {
            fontSize: defaultTheme.typography.pxToRem(14),
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          width: "100%",
        },
        img: {
          maxWidth: "100%",
          height: "auto",
        },
        "strong, b": {
          fontWeight: "bolder",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up("lg")]: {
            paddingLeft: 0,
            paddingRight: 0,
            maxWidth: 1280,
          },
        },
      },
    },
    MuiOutlinedInput: {
      /**
       * How to override defaults? Example:
       * @see https://github.com/mui-org/material-ui/blob/9bd4277ecd660ebe2fd4cb08960f58e98ddb6d43/packages/material-ui/src/OutlinedInput/OutlinedInput.js#L15
       */
      styleOverrides: {
        root: {
          fontSize: pxToRem(16),
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: contextualColors.uiSecondary,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: pxToRem(16),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          "&.Mui-disabled": {
            borderColor: contextualColors.brandPrimaryInactive,
            color: contextualColors.brandPrimaryInactive,
          },
        },
        contained: {
          "&.Mui-disabled": {
            backgroundColor: contextualColors.brandPrimaryInactive,
            color: contextualColors.negative,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: contextualColors.negative,
          color: contextualColors.uiPrimary /* dark grey */,
        },
        colorSecondary: {
          backgroundColor: "transparent",
          color: contextualColors.negative,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "1rem",

          "&.Mui-selected": {
            backgroundColor: `${contextualColors.bgSecondaryAccent} !important`,
          },
        },
      },
    },
  },
  gridGap: {
    4: 4,
    8: 8,
    12: 12,
    16: 16,
  },
})
