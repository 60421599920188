import { GraphQLError } from "graphql"
import { ClientError } from "graphql-request"

const CRITICAL_CODES = [
  "ACCESS_TOKEN_MISSING",
  "ACCESS_TOKEN_INVALID",
  "UNKNOWN_ENVIRONMENT",
  "UNKNOWN_SPACE",
  "MISSING_QUERY",
  "QUERY_TOO_BIG",
  "INVALID_QUERY_FORMAT",
  "PERSISTED_QUERY_NOT_FOUND",
  "PERSISTED_QUERY_MISMATCH",
  "INVALID_VARIABLES_FORMAT",
  "TOO_COMPLEX_QUERY",
  "QUERY_OPERATION_NAME_MISMATCH",
  "INTERNAL_SERVER_ERROR",
  "RATE_LIMIT_EXCEEDED",
]

/**
 * @see https://www.contentful.com/developers/docs/references/graphql/#/reference/graphql-errors
 * as a base, we treat non partial errors as critical
 * with these kind of errors partial response isn't possible and recovery is impossible
 */
const isFatalGraphQlError = (error: GraphQLError) => {
  const { details, code } = error?.extensions?.contentful
  const filterBoxIsBroken = () =>
    code.toUpperCase() === "UNRESOLVABLE_LINK" &&
    (details?.field === "config" || details?.field === "filterBox")

  return CRITICAL_CODES.includes(code.toUpperCase()) || filterBoxIsBroken()
}

export const extendErrors = (
  errors: readonly GraphQLError[] | GraphQLError | undefined
) => {
  if (!errors) {
    return errors
  }

  const makeError = (error: GraphQLError) => {
    const f = isFatalGraphQlError(error)

    if (!f) {
      return error
    }

    return {
      ...error,
      extensions: {
        ...error.extensions,
        adviqo: {
          code: "FATAL",
        },
      },
    }
  }

  if (Array.isArray(errors)) {
    return errors.map(makeError)
  } else {
    return [makeError(errors as GraphQLError)]
  }
}

export const extractErrors = (responseError: ClientError) => {
  return responseError.response.errors
}
