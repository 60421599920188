"use client"

import React, { useEffect, useRef, useState } from "react"

import { useTranslations } from "next-intl"

import { Snackbar } from "../../ui-lib/_common"
import { useAuthentication } from "../../hooks/authentication"
import { usePrevious } from "../../hooks/usePrevious"
import usePromotions from "./hooks/usePromotions"
import { useDomRefs } from "../../hooks/domRefs"

const PromotionsSnackbar: React.FC = () => {
  const { isAuthenticated } = useAuthentication()

  const ref = useRef<HTMLInputElement>(null)
  const { addRef, delRef } = useDomRefs()

  const translate = useTranslations()

  const [shouldQueryPromotions, setShouldQueryPromotions] = useState(false)

  const prevIsAuthenticated = usePrevious(isAuthenticated)

  const { hasOnlyMarketplacePromotions } = usePromotions(
    {},
    shouldQueryPromotions
  )

  useEffect(() => {
    if (
      prevIsAuthenticated !== undefined &&
      isAuthenticated !== prevIsAuthenticated
    ) {
      setShouldQueryPromotions(isAuthenticated)
    }
  }, [isAuthenticated, prevIsAuthenticated])

  useEffect(() => {
    if (hasOnlyMarketplacePromotions) {
      addRef("promotionsSnackbar", ref)
    }
  }, [addRef, hasOnlyMarketplacePromotions])

  if (!shouldQueryPromotions || !hasOnlyMarketplacePromotions) return null

  return (
    <Snackbar
      ref={ref}
      isOpen
      closingComponent="text"
      autoHideDuration={10000}
      onClose={() => {
        delRef("promotionsSnackbar")
      }}
    >
      {translate("productFlow.promotions.marketplace.snackbar.text")}
    </Snackbar>
  )
}

export default PromotionsSnackbar
