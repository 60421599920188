import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react"
import authErrorMiddleware from "../../utils/rest/swrMiddleware"
import trackingMiddleware from "../../utils/rest/trackingMiddleware"
import { fetchWithConfig } from "../../utils/rest"
import { getRuntimeConfig } from "../../utils/runtimeConfig"
import { isAuthError } from "../../utils/rest/utils"
import PendingRequests from "../../utils/rest/pendingRequests"
import { emitCustomEvent } from "../../utils/events"
import { AdviiCustomEvent } from "../../types"
import { SWRConfig } from "swr"

const SWRWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { getData: getFPData } = useVisitorData(
    { extendedResult: true },
    { immediate: false }
  )

  return (
    <SWRConfig
      value={{
        use: [authErrorMiddleware, trackingMiddleware({ getFPData })],
        fetcher: ([url, config = {}, service]) =>
          fetchWithConfig(
            {
              url,
              ...config,
            },
            getRuntimeConfig,
            service
          ),
        onErrorRetry: (error, _key, _config, revalidate) => {
          if (!isAuthError(error)) {
            return
          }

          PendingRequests.addRequest(() => revalidate())
          emitCustomEvent(AdviiCustomEvent.TOKEN_EXPIRED)
        },
      }}
    >
      {children}
    </SWRConfig>
  )
}

export default SWRWrapper
